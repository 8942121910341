import Vue from "vue";
import Vuex from "vuex";
import util from "@/assets/js/util";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        registered: false,
        me: {},
        user: {},
        is_authenticated: false,
        is_auth_loading: true,
        auth_headers: {},
        settings: {},

        token: util.getToken(),
    },
    mutations: {
        settings(state, data) {
            state.settings = data;
        },
        auth_headers(state, data) {
            state.auth_headers = data;
        },
        is_auth_loading(state, data) {
            state.is_auth_loading = data;
        },
        registered(state, data) {
            state.registered = data;
        },
        me(state, data) {
            state.me = data;
        },
        user(state, data) {
            state.user = data;
        },
        is_authenticated(state, data) {
            state.is_authenticated = data;
        },
    },
    actions: {},
    modules: {},
});