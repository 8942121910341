<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: "home",

  watch: {
    me() {
      this.$router.push("/lobby");
    },
  },
  mounted() {
    this.$router.push("/lobby");
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #e7e7e7;
  height: 100vh;
  position: relative;
  &__img {
    width: 100%;
    height: 100%;
  }
}
</style>
