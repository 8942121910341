<template>
  <div id="app">
    <div id="note" v-if="settings.show_bar">
      <div>
        {{ settings.bar_message + " " }}
        <a
          :href="settings.button_url"
          v-if="settings.button_url"
          target="_blank"
          class="btn btn-sm btn-danger"
        >
          {{ settings.button_label }}
        </a>
      </div>
      <div>
        Please clear cache and view on Firefox browser for optimal viewing
        experience.
      </div>
    </div>
    <div class="bProt"></div>
    <router-view />
  </div>
</template>
<script>
import util from "./assets/js/util";
import { mapState } from "vuex";
import Api from "./assets/js/Api";

export default {
  name: "app",
  computed: mapState(["user", "me", "settings"]),
  data() {
    return {
      attendance_interval: false,
    };
  },
  watch: {
    $route(nval, oval) {
      let self = this;
      setTimeout(() => {
        util.logActivity(self);
      }, 500);

      if (oval.path && oval.path != "/") {
        if (this.$route.path == "/auditorium") return;
        setTimeout(() => {
          self.setAttendance();
        }, 1000);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.attendance_interval);
    next();
  },
  methods: {
    setAttendance() {
      if (this.$route.name == "auditorium") {
        clearInterval(this.attendance_interval);

        return;
      }
      let self = this;
      clearInterval(this.attendance_interval);

      // Api.post(
      //     "/event-duration/store",
      //     {event: this.$route.path},
      //     util.getAuthConfig()
      // ).then((res) => {
      //   let event_duration_id = res.data.event_duration_id;
      //   self.attendance_interval = setInterval(function () {
      //     Api.get(
      //         `/event-duration/update?event_duration_id=${event_duration_id}`,
      //         util.getAuthConfig()
      //     ).then((res) => {
      //     });
      //   }, 10000);
      // });
    },
    watchSocketChanges() {
      Echo.channel("trigger-change").listen("TriggerChange", (e) => {
        this.getChangeType(e.message);
      });
    },
    getSettingsFromApi() {
      Api.get("/get_settings").then((res) => {
        console.log(res);
        this.setSettings(res.data.data);
      });
    },
    setSettings(settings) {
      if (settings) localStorage.setItem("settings", JSON.stringify(settings));
      if (localStorage.getItem("settings")) {
        let settings = JSON.parse(localStorage.getItem("settings"));
        this.$store.commit("settings", settings);

        return;
      }

      this.$store.commit("settings", {});
    },

    getChangeType(message) {
      switch (message.type) {
        case "settings":
          this.setSettings(message.data);

        default:
          break;
      }
    },
    getOrientation() {
      var mql = window.matchMedia("(orientation: portrait)");
      var blockdiv = document.querySelector(".bProt");

      if (mql.matches) {
        blockdiv.style.display = "flex";
      } else {
        blockdiv.style.display = "none";
      }

      // Add a media query change listener
      mql.addListener(function (m) {
        if (m.matches) {
          blockdiv.style.display = "flex";
        } else {
          blockdiv.style.display = "none";
        }
      });
    },
  },
  mounted() {
    util.getIp();
    let self = this;
    setTimeout(() => {
      self.setAttendance();
    }, 60000);
    this.watchSocketChanges();
    this.getOrientation();
    this.getSettingsFromApi();
    util.logActivity(this);
  },
};
</script>
<style lang="scss" scoped>
#note {
  background: #503628;
  color: #fff;
  margin: 0 auto;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px black;
  -moz-box-shadow: 0 0 5px black;
  box-shadow: 0 0 5px black;
  font-size: 14px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  font-weight: 600;
}

@media (min-width: 320px) and (max-width: 1024px) {
  #note {
    position: relative;
  }
}
</style>
