import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    meta: {
      onlyGuest: true,
    },
  },

  {
    path: "/lobby",
    name: "lobby",
    component: () => import("../views/lobby.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/messagewall",
    name: "messagewall",
    component: () => import("../views/messagewall.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/photogallery",
    name: "photogallery",
    component: () => import("../views/photogallery.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/apac",
    name: "apac",
    component: () => import("../views/apac.vue"),
  },
  {
    path: "/emea",
    name: "emea",
    component: () => import("../views/emea.vue"),
  },
  {
    path: "/americas-ex-us",
    name: "americas",
    component: () => import("../views/americas.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
