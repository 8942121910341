import Api from "./Api";
import axios from "axios";
const util = {
  async logActivity(app, event = null, type = null) {
    if (!type) type = "visit";

    let ip = await this.getIp();
    let path = app.$route.path;
    if (path == "/") return;
    if (event) {
      path = event;
      type = type;
    }

    Api.post("logactivity", {
      event: path,
      type: type,
      ip: ip,
    }).then();
  },

  async auth_middleware(app, event = null, type = null) {
    return;
    // if (app.$route.meta.guest) {
    //     return;
    // }
    if (!type) type = "visit";

    let path = app.$route.path;

    if (event) {
      path = event;
      type = type;
    }

    let token = localStorage.getItem("participant_token");

    if (!token) {
      app.$store.commit("me", { id: 0 });
      app.$store.commit("is_auth_loading", false);

      if (app.$route.meta.requiresAuth) {
        this.redirectToHome(app);
      }
      return;
      // un auth logic
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
      params: {
        type: type,
        event: path,
      },
    };
    app.$store.commit("auth_headers", config);
    Api.get("/me", config)
      .then((res) => {
        if (!res.data.error) {
          app.$store.commit("me", res.data.user);
          app.$store.commit("user", res.data.user);
          app.$store.commit("is_authenticated", true);
          app.$store.commit("is_auth_loading", false);

          if (res.data.user.booth) {
            if (app.$route.path != "/exhibitor/chat")
              app.$router.push("/exhibitor/chat");
          }
        } else {
          app.$store.commit("me", { id: 0 });
          app.$store.commit("is_authenticated", false);
          app.$store.commit("is_auth_loading", false);

          if (app.$route.meta.requiresAuth) {
            this.redirectToHome(app);
          }
        }
      })
      .catch((res) => {
        app.$store.commit("me", { id: 0 });
        app.$store.commit("is_authenticated", false);
        app.$store.commit("is_auth_loading", false);
        if (app.$route.meta.requiresAuth) {
          this.redirectToHome(app);
        }
      });
  },
  redirectToHome(app) {
    app.$router.push("/");
  },
  getAuthConfig() {
    let token = localStorage.getItem("participant_token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
    };
  },
  async getIp() {
    if (!localStorage.getItem("ip_address")) {
      const res = await axios.get("https://api.ipify.org?format=json");
      let ip = res.data.ip;
      await localStorage.setItem("ip_address", ip);
      return await localStorage.getItem("ip_address");
    } else {
      return await localStorage.getItem("ip_address");
    }
  },

  getToken() {
    return localStorage.getItem("participant_token");
  },
};
export default util;
