import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "./assets/css/scss/app.scss";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import axios from "axios";
import Echo from "laravel-echo";
window.Pusher = require("pusher-js");
Vue.config.productionTip = false;
Vue.use(VueToast);

let token = localStorage.getItem("participant_token");

window.Echo = new Echo({
    broadcaster: "pusher",
    key: "c37bcaa254a2fb40c681",
    cluster: "mt1",
    wsHost: 'admin-jpm.klobbi.com',
    // wsHost: location.hostname,

    // wsPort: 6001,
    auth: {
        headers: {
            Authorization: "Bearer " + token,
        },
    },
    wssPort: 8443,
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios
                    .post(
                        "https://admin-jpm.klobbi.com/api/broadcasting/auth", {
                            socket_id: socketId,
                            channel_name: channel.name,
                        },
                        util.getAuthConfig()
                    )
                    .then((response) => {
                        callback(false, response.data);
                    })
                    .catch((error) => {
                        callback(true, error);
                    });
            },
        };
    },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
